import React, {useState} from 'react';
import './Imlementation.css';

function FormTracking(props) {
  const [name, setName] = useState('');
  const [remark, setRemark] = useState('');
  const [phone, setPhone] = useState('');

  const submit = e => {
    e.preventDefault();
    setName('');
    setRemark('');
    setPhone('');
    alert('Send!\nName: ' + name + '\nRemark: ' + remark + '\nPhone: ' + phone);
  };

  return (
    <form className="form glass" id="bram-hagen-form" onSubmit={e => submit(e)}>
      <h1 className="title">Form Tracking</h1>
      <span className="title">Naam: </span>
      <input id="name" className="full-with" value={name} onChange={e => setName(e.target.value)} />
      <span className="title">Opmerking: </span>
      <input id="remark" className="full-with" value={remark} onChange={e => setRemark(e.target.value)} />
      <span className="title">Telefoonnummer: </span>
      <input id="phone" className="full-with" value={phone} onChange={e => setPhone(e.target.value)} />
      <button className="btn">Submit</button>
    </form>
  );
}

export default FormTracking;
