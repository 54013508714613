import React from 'react';
import Card from '../Card';
import './Imlementation.css';

function Implementation2(props) {
  // Dynamic page load

  return (
    <div className="adcalls-implementation">
      <Card
        header="Pagina 2"
        className="color-2"
        text="Je kan me bellen op 0617007001, maar waarom wordt het nummer niet vervangen?"
      />

      <a href="/adcalls" className="prev">
        {'< Vorige'}
      </a>
      <a href="/adcalls-3" className="next">
        Volgende >
      </a>
    </div>
  );
}

export default Implementation2;
