import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import About from './About';
import FormTracking from './AdCallsImplementation/FormTracking';
import Implementation1 from './AdCallsImplementation/Implementation1';
import Implementation2 from './AdCallsImplementation/Implementation2';
import Implementation3 from './AdCallsImplementation/Implementation3';
import Implementation4 from './AdCallsImplementation/Implementation4';
import Implementation5 from './AdCallsImplementation/Implementation5';
import Implementation6 from './AdCallsImplementation/Implementation6';
import Implementation7 from './AdCallsImplementation/Implementation7';
import './App.css';
import Card from './Card.js';
import Css from './css';
import Emoji from './Emoji';
import Clock from './Clock';
import Error404 from './Error404.js';
import Menu from './Menu';
import TicTacToe3d from './TicTacToe3d';

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Menu />
        <div className="small-clock">
          <Clock />
        </div>
        <Routes>
          <Route
            path="/"
            element={<Card header="Bram Hagen" text="This is my website, I'm currently working on it." />}
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/games"
            element={
              <Card
                className="color-2"
                header="Games"
                text="So far I have built 1 game."
                link="/games/tic-tac-toe-plus"
                linkText="Tic Tac Toe +"
              />
            }
          />
          <Route
            path="/wood"
            element={
              <Card className="color-3" header="Wood" text="I've build some real life things with wood. Stay tuned." />
            }
          />
          <Route path="/css" element={<Css />} />
          <Route
            path="/contact"
            element={
              <Card className="color-5" header="Contact" text="You can send me an email at email@bramhagen.nl" />
            }
          />
          <Route path="/css/emoji" element={<Emoji />} />
          <Route path="/css/clock" element={<Clock />} />
          <Route path="/adcalls" element={<Implementation1 />} />
          <Route path="/adcalls-2" element={<Implementation2 />} />
          <Route path="/adcalls-3" element={<Implementation3 />} />
          <Route path="/adcalls-4" element={<Implementation4 />} />
          <Route path="/adcalls-5" element={<Implementation5 />} />
          <Route path="/adcalls-6" element={<Implementation6 />} />
          <Route path="/adcalls-7" element={<Implementation7 />} />
          <Route path="/form-tracking" element={<FormTracking />} />
          <Route path="/games/tic-tac-toe-plus" element={<TicTacToe3d />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
