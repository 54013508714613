import React from 'react';
import Card from '../Card';
import './Imlementation.css';

function Implementation6(props) {
  return (
    <div className="adcalls-implementation">
      <Card
        header="Pagina 7"
        className="color-7"
        text={
          <p>
            Je kan me bellen op <div className="replace-text" data-text="0617007001" />
          </p>
        }
      />

      <a href="/adcalls-4" className="prev">
        {'< Vorige'}
      </a>
    </div>
  );
}

export default Implementation6;
