import React from 'react';
import Card from '../Card';
import './Imlementation.css';
import telNr from './tel-nr.png';

function Implementation6(props) {
  return (
    <div className="adcalls-implementation">
      <Card
        header="Pagina 6"
        className="color-6"
        text={
          <p>
            Je kan me bellen op{' '}
            <a href="tel:0617007001">
              <img src={telNr} alt="0617007001" />
            </a>
          </p>
        }
      />

      <a href="/adcalls-5" className="prev">
        {'< Vorige'}
      </a>
      <a href="/adcalls-7" className="next">
        Volgende >
      </a>
    </div>
  );
}

export default Implementation6;
