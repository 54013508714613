import React, {useState} from 'react';
import './TicTacToe3d.css';

function TicTacToe3d(props) {
  const COLOR_INDEX = 0;
  const SIZE_INDEX = 1;
  const NUMBER_INDEX = 2;
  const INITIAL_UNIT = ['', false, false];
  const WINNER_COLOR_INDEX = 0;
  const WINNER_PLACE_INDEX = 1;
  const WINNER_DIRECTION_INDEX = 2;
  const SCORE_RED = 0;
  const SCORE_BLUE = 1;
  const SCORE_DRAW = 2;

  const [start, setStart] = useState(false);
  const [playerRed, setPlayerRed] = useState('');
  const [playerBlue, setPlayerBlue] = useState('');
  const [numberOfSizes, setNumberOfSizes] = useState(3);
  const [numberOfUnits, setNumberOfUnits] = useState(3);
  const [firstTo, setFirstTo] = useState(3);
  const [startingPlayer, setStartingPlayer] = useState('random');
  const [startedPlayer, setStartedPlayer] = useState('');
  const [activeUnit, setActiveUnit] = useState(INITIAL_UNIT);
  const [playedUnits, setPlayedUnits] = useState({});
  const [turn, setTurn] = useState('');
  const [totalGames, setTotalGames] = useState(0);
  const [winner, setWinner] = useState(['', '', '']);
  const [noMovesText, setNoMovesText] = useState(false);
  const [matchWinner, setMatchWinner] = useState('');
  const [score, setScore] = useState([0, 0, 0]);
  const [field, setField] = useState([
    [INITIAL_UNIT, INITIAL_UNIT, INITIAL_UNIT],
    [INITIAL_UNIT, INITIAL_UNIT, INITIAL_UNIT],
    [INITIAL_UNIT, INITIAL_UNIT, INITIAL_UNIT],
  ]);
  const extraSizePerSize = Math.floor(40 / numberOfSizes);

  const setToInit = function (rematch = false) {
    setActiveUnit(INITIAL_UNIT);
    setPlayedUnits({});
    setWinner(['', '', '']);
    setField([
      [INITIAL_UNIT, INITIAL_UNIT, INITIAL_UNIT],
      [INITIAL_UNIT, INITIAL_UNIT, INITIAL_UNIT],
      [INITIAL_UNIT, INITIAL_UNIT, INITIAL_UNIT],
    ]);
    setNoMovesText(false);

    if (rematch) {
      setScore([0, 0, 0]);
      setMatchWinner('');
      setStart(false);
      setTotalGames(0);
    } else {
      let newTotalGames = totalGames + 1;
      setTotalGames(newTotalGames);
      setTurn(newTotalGames % 2 === 0 ? startedPlayer : startedPlayer === 'red' ? 'blue' : 'red');
    }
  };

  if (!start) {
    return (
      <div className="container">
        <div className="glass tictactoe3d setup">
          <h1>Tic Tac Toe +</h1>
          <div className="input-row">
            Player red: <input value={playerRed} onChange={e => setPlayerRed(e.target.value)} />
          </div>
          <div className="input-row">
            Player Blue: <input value={playerBlue} onChange={e => setPlayerBlue(e.target.value)} />
          </div>
          <div className="input-row">
            Number of sizes:{' '}
            <input value={numberOfSizes} type="number" onChange={e => setNumberOfSizes(e.target.value)} />
          </div>
          <div className="input-row">
            Units per size:{' '}
            <input value={numberOfUnits} type="number" onChange={e => setNumberOfUnits(e.target.value)} />
          </div>
          <div className="input-row">
            Number of wins: <input value={firstTo} type="number" onChange={e => setFirstTo(e.target.value)} />
          </div>
          <div className="input-row">
            Starting player:
            <div>
              <input
                type="radio"
                id="random"
                name="starter"
                checked={startingPlayer === 'random'}
                onChange={() => setStartingPlayer('random')}
              />
              <label htmlFor="random">Random</label>
              <input
                type="radio"
                id="red"
                name="starter"
                checked={startingPlayer === 'red'}
                onChange={() => setStartingPlayer('red')}
              />
              <label htmlFor="red">Red</label>
              <input
                type="radio"
                id="blue"
                name="starter"
                checked={startingPlayer === 'blue'}
                onChange={() => setStartingPlayer('blue')}
              />
              <label htmlFor="blue">Blue</label>
            </div>
          </div>
          <button
            onClick={() => {
              setStart(true);
              if (startingPlayer === 'random') {
                let playerStarting = Math.round(Math.random()) ? 'red' : 'blue';
                setTurn(playerStarting);
                setStartedPlayer(playerStarting);
              } else {
                setTurn(startingPlayer);
                setStartedPlayer(startingPlayer);
              }
            }}
          >
            Start
          </button>
        </div>
      </div>
    );
  }

  if (matchWinner !== '') {
    return (
      <div className="container">
        <div className="glass tictactoe3d match-winner">
          Winner!{' '}
          {matchWinner === 'red' ? (playerRed ? playerRed : 'Player red') : playerBlue ? playerBlue : 'Player blue'}
        </div>
        <button onClick={() => setToInit(true)}>Rematch</button>
      </div>
    );
  }

  const getUnit = function (color, sizeNumber, index, selectable = true, available = true) {
    let size = 40 + extraSizePerSize * sizeNumber;

    let classList = '';
    if (
      activeUnit[COLOR_INDEX] === color &&
      activeUnit[SIZE_INDEX] === sizeNumber &&
      activeUnit[NUMBER_INDEX] === index
    ) {
      classList += ' active';
    }

    let onClick = null;
    if (color === turn && selectable && available) {
      onClick = () => setActiveUnit([color, sizeNumber, index]);
    }

    if (!available) {
      classList += ' disabled';
    }

    return (
      <div
        data-size={sizeNumber}
        className={'unit ' + color + classList}
        key={color + size + index}
        style={{height: size, width: size, fontSize: size - 10}}
        onClick={onClick}
      />
    );
  };

  const getAvailableUnits = function (color, returnHighestAvailable = false) {
    let units = [];
    for (let i = 0; i < numberOfSizes; i++) {
      let row = [];
      let size = numberOfSizes - i;
      for (let j = 0; j < numberOfUnits; j++) {
        let available = true;
        if (playedUnits[color]?.[size]?.[j] !== undefined) {
          available = false;
        }
        if (returnHighestAvailable && available) {
          return size;
        }
        row.push(getUnit(color, size, j, true, available));
      }
      units.push(
        <div key={color + i} className="unit-row">
          {row}
        </div>,
      );
    }
    if (returnHighestAvailable) {
      return false;
    }

    return units;
  };

  const placeUnit = function (row, col) {
    if (activeUnit[COLOR_INDEX] === '') {
      return;
    }

    let newField = field;

    let placeAt = newField[row][col];
    if (placeAt[SIZE_INDEX] >= activeUnit[SIZE_INDEX]) {
      return;
    }
    if (placeAt[COLOR_INDEX] === turn) {
      if (!window.confirm('You already have this space, are you sure you want to overwrite it with a bigger unit?')) {
        return;
      }
    }
    newField[row][col] = activeUnit;
    setField(newField);

    let newPlayedUnits = playedUnits;
    if (newPlayedUnits[activeUnit[COLOR_INDEX]] === undefined) {
      newPlayedUnits[activeUnit[COLOR_INDEX]] = {};
    }
    if (newPlayedUnits[activeUnit[COLOR_INDEX]][activeUnit[SIZE_INDEX]] === undefined) {
      newPlayedUnits[activeUnit[COLOR_INDEX]][activeUnit[SIZE_INDEX]] = {};
    }
    newPlayedUnits[activeUnit[COLOR_INDEX]][activeUnit[SIZE_INDEX]][activeUnit[NUMBER_INDEX]] = true;
    setPlayedUnits(newPlayedUnits);

    let lastTurn = activeUnit[COLOR_INDEX];
    if (lastTurn === 'red') {
      setTurn('blue');
    } else {
      setTurn('red');
    }
    setActiveUnit(['', false, false]);
    setNoMovesText(false);
    checkEnd(lastTurn);
  };

  const getField = function () {
    let rows = [];
    for (let row = 0; row < field.length; row++) {
      let columns = [];
      for (let col = 0; col < field[row].length; col++) {
        const space = field[row][col];
        if (space[COLOR_INDEX] !== '') {
          let classList = '';
          if (winner[WINNER_COLOR_INDEX] !== '') {
            if (
              (winner[WINNER_DIRECTION_INDEX] === 'row' && winner[WINNER_PLACE_INDEX] === row) ||
              (winner[WINNER_DIRECTION_INDEX] === 'col' && winner[WINNER_PLACE_INDEX] === col) ||
              (winner[WINNER_DIRECTION_INDEX] === 'diag' &&
                winner[WINNER_PLACE_INDEX] === 0 &&
                ((col === 0 && row === 0) || (col === 1 && row === 1) || (col === 2 && row === 2))) ||
              (winner[WINNER_DIRECTION_INDEX] === 'diag' &&
                winner[WINNER_PLACE_INDEX] === 2 &&
                ((col === 0 && row === 2) || (col === 1 && row === 1) || (col === 2 && row === 0)))
            ) {
              classList = ' winner-space';
            }
          }
          columns.push(
            <div
              key={'col' + row + col}
              className={'tictactoe-field-col' + classList}
              onClick={() => placeUnit(row, col)}
            >
              {getUnit(space[COLOR_INDEX], space[SIZE_INDEX], space[NUMBER_INDEX], false)}
            </div>,
          );
        } else {
          columns.push(
            <div key={'col' + row + col} className="tictactoe-field-col" onClick={() => placeUnit(row, col)} />,
          );
        }
      }
      rows.push(
        <div key={'row' + row} className="tictactoe-field-row">
          {columns}
        </div>,
      );
    }
    return <div className="tictactoe-field">{rows}</div>;
  };

  const checkEnd = function (lastTurn) {
    //check for winner
    let maxArray = [];
    for (let i = 0; i < 3; i++) {
      if (
        field[i][0][COLOR_INDEX] !== '' &&
        field[i][0][COLOR_INDEX] === field[i][1][COLOR_INDEX] &&
        field[i][0][COLOR_INDEX] === field[i][2][COLOR_INDEX]
      ) {
        markWinner(field[i][0][COLOR_INDEX], i, 'row');
        return;
      } else if (
        field[0][i][COLOR_INDEX] !== '' &&
        field[0][i][COLOR_INDEX] === field[1][i][COLOR_INDEX] &&
        field[0][i][COLOR_INDEX] === field[2][i][COLOR_INDEX]
      ) {
        markWinner(field[0][i][COLOR_INDEX], i, 'col');
        return;
      }
      maxArray.push(Math.min(field[i][0][SIZE_INDEX] || 0, field[i][1][SIZE_INDEX] || 0, field[i][2][SIZE_INDEX] || 0));
    }
    if (winner[WINNER_COLOR_INDEX] === '') {
      if (
        field[0][0][COLOR_INDEX] !== '' &&
        field[0][0][COLOR_INDEX] === field[1][1][COLOR_INDEX] &&
        field[0][0][COLOR_INDEX] === field[2][2][COLOR_INDEX]
      ) {
        markWinner(field[0][0][COLOR_INDEX], 0, 'diag');
        return;
      } else if (
        field[0][2][COLOR_INDEX] !== '' &&
        field[0][2][COLOR_INDEX] === field[1][1][COLOR_INDEX] &&
        field[0][2][COLOR_INDEX] === field[2][0][COLOR_INDEX]
      ) {
        markWinner(field[0][2][COLOR_INDEX], 2, 'diag');
        return;
      }
    }

    //check for draw
    let lowestOnField = Math.min(maxArray[0] || 0, maxArray[1] || 0, maxArray[2] || 0);
    if (lowestOnField >= getAvailableUnits(lastTurn === 'red' ? 'blue' : 'red', true)) {
      if (lowestOnField >= getAvailableUnits(lastTurn, true)) {
        markWinner('draw', '', '');
      } else {
        setNoMovesText(true);
        setTurn(lastTurn);
      }
    }
  };

  const markWinner = function (color, index, direction) {
    let newScore = score;
    if (color === 'red') {
      newScore[SCORE_RED] += 1;
      if (newScore[SCORE_RED] >= firstTo) {
        setMatchWinner('red');
      }
    } else if (color === 'blue') {
      newScore[SCORE_BLUE] += 1;
      if (newScore[SCORE_BLUE] >= firstTo) {
        setMatchWinner('blue');
      }
    } else if (color === 'draw') {
      newScore[SCORE_DRAW] += 1;
    }
    setWinner([color, index, direction]);
    setScore(newScore);
  };

  const red = playerRed ? playerRed : 'Player red';
  const blue = playerBlue ? playerBlue : 'Player blue';
  let extraText = null;
  if (noMovesText) {
    extraText = (
      <div className="text">
        {turn === 'red' ? blue : red} can't place units anymore. It's the turn of {turn} again.
      </div>
    );
  }

  let topElement = (
    <>
      <div className="text">Turn: {turn === 'red' ? red : blue}</div>
      {extraText}
    </>
  );
  if (winner[WINNER_COLOR_INDEX] !== '') {
    topElement = (
      <div className="winner-box">
        <div className="text">WINNER: {winner[WINNER_COLOR_INDEX]}</div>
        <button onClick={() => setToInit()}>Next game</button>
      </div>
    );
  }
  return (
    <>
      <div className="container tictactoe3d game">
        <div className="score-board">
          <div className="score-column">
            <div className="name">{playerRed ? playerRed : 'Red'}</div>
            <div className="score">{score[SCORE_RED]}</div>
          </div>
          <div className="score-column">
            <div className="name">Draw</div>
            <div className="score">{score[SCORE_DRAW]}</div>
          </div>
          <div className="score-column">
            <div className="name">{playerBlue ? playerBlue : 'Blue'}</div>
            <div className="score">{score[SCORE_BLUE]}</div>
          </div>
        </div>
        <div className="top">{topElement}</div>
        <div className="bottom">
          <div className="tictactoe-player">{getAvailableUnits('red')}</div>
          {getField()}
          <div className="tictactoe-player">{getAvailableUnits('blue')}</div>
        </div>
      </div>
    </>
  );
}

export default TicTacToe3d;
