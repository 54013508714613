import React from 'react';
import {Link} from 'react-router-dom';
import Card from '../Card';
import './Imlementation.css';

function Implementation3(props) {
  return (
    <div className="adcalls-implementation">
      <Card header="Pagina 3" className="color-3" text="Je kan me bellen op 0617007001" />

      <Link to="/adcalls-2" className="prev">
        {'< Vorige'}
      </Link>
      <a href="/adcalls-4" className="next">
        Volgende >
      </a>
    </div>
  );
}

export default Implementation3;
