import React from 'react';
import Card from '../Card';
import './Imlementation.css';

function Implementation4(props) {
  return (
    <div className="adcalls-implementation">
      <Card header="Pagina 4" className="color-4" text="Je kan me bellen op 0617007001" />

      <a href="/adcalls-3" className="prev">
        {'< Vorige'}
      </a>
      <a href="/adcalls-5" className="next">
        Volgende >
      </a>
    </div>
  );
}

export default Implementation4;
