import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

let script = document.createElement('script');
script.async = true;
if (document.location.pathname === '/adcalls-4') {
  script.src = 'https://script.adcalls.nl/418618f6-5523-4c08-8740-85f6a29a0acd.js';
} else {
  script.src = 'https://script.adcalls.nl/418618f6-5523-4c08-8740-85f6a29a0acc.js';
}
script.type = 'text/javascript';

if (document.location.pathname !== '/adcalls-3') {
  document.getElementsByTagName('head')[0].append(script);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
