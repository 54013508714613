import React from 'react';
import './Cube.css';

export default class Cube extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div className="container">
        <div className="cube">
          <div className="">
            <span className="top" />
            <span className="side-1">404</span>
            <span className="side-2">Page</span>
            <span className="side-3">not</span>
            <span className="side-4">found</span>
            <span className="bottom" />
          </div>
        </div>
      </div>
    );
  }
}
