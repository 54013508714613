import React, {useState} from 'react';
import Tilty from 'react-tilty';
import './About.css';

function About(props) {
  const [active, setActive] = useState(false);

  let cardsSubjects = [
    {
      title: 'Work',
      text: "I'm the lead-developer of AdCalls. We boost your number of conversions and your revenue by providing insight into all call conversions per traffic source or campaign. Gather better data and achieve more conversions with your marketing budget.",
      image: (
        <>
          <div className="about-image about-adcalls" />
          <div className="about-image about-adcalls" />
          <div className="about-image about-adcalls" />
          <div className="about-image about-adcalls" />
          <div className="about-image about-adcalls" />
        </>
      ),
    },
    {
      title: 'Sport',
      text: 'I play korfball at De Corvers. I started playing since I was 10 years old.',
      image: (
        <>
          <div className="about-image about-korfbal about-korfbal-1" />
          <div className="about-image about-korfbal about-korfbal-2" />
          <div className="about-image about-korfbal about-korfbal-3" />
          <div className="about-image about-korfbal about-korfbal-4" />
          <div className="about-image about-korfbal about-korfbal-5" />
        </>
      ),
    },
    {
      title: 'City',
      text: 'I was born and raised in Hilversum, The Netherlands.',
      image: (
        <>
          <div className="about-image about-city about-city-1" />
          <div className="about-image about-city about-city-2" />
          <div className="about-image about-city about-city-3" />
          <div className="about-image about-city about-city-4" />
          <div className="about-image about-city about-city-5" />
        </>
      ),
    },
  ];

  let cards = Object.keys(cardsSubjects).map((key, index) => {
    const cardSubject = cardsSubjects[key];
    const classes = 'about-card' + (active === key ? ' active' : '');
    const setTo = key === active ? false : key;
    const image = cardSubject.image ? cardSubject.image : null;
    return (
      <div key={index} className={classes}>
        {image}
        <Tilty max={20} className="tilt">
          <div className="about-card-inner glass" onClick={() => setActive(setTo)}>
            <h1>{cardSubject.title}</h1>
            <p>{cardSubject.text}</p>
          </div>
        </Tilty>
      </div>
    );
  });

  return <div className="about-wrapper">{cards}</div>;
}

export default About;
