import React from 'react';
import './Emoji.css';

export default class Emoji extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div className="container full" onMouseMoveCapture={e => this.rollEyeBalls(e)}>
        <div className="face">
          <div className="eyes">
            <div className="eyebrows" />
            <div className="eye-wrapper">
              <div className="eyelid" />
              <div className="eye" />
            </div>
            <div className="eye-wrapper">
              <div className="eyelid" />
              <div className="eye" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  rollEyeBalls(event) {
    const eye = document.querySelectorAll('.eye');
    eye.forEach(function (eye) {
      let x = eye.getBoundingClientRect().left + eye.clientWidth / 2;
      let y = eye.getBoundingClientRect().top + eye.clientHeight / 2;
      let radian = Math.atan2(event.pageX - x, event.pageY - y);
      let rot = radian * (180 / Math.PI) * -1 - 90;
      eye.style.transform = 'rotate(' + rot + 'deg)';
    });
  }
}
