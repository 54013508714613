import moment from 'moment';
import React from 'react';
import './Clock.css';

let time = moment();
let interval = null;

export default class Emoji extends React.Component {
  static propTypes = {};

  componentDidMount() {
    interval = setInterval(() => {
      time = moment();
      this.forceUpdate();
    }, 1000);
  }

  componentWillUnmount() {
    if (interval !== null) {
      clearInterval(interval);
    }
  }

  render() {
    let numbersDate = [];
    let numbersTime = [];
    const dateString = time.format('DD-MM-YYYY');
    const timeString = time.format('HH:mm:ss');
    for (let i = 0; i < dateString.length; i++) {
      const char = dateString[i];
      numbersDate.push(this.getNumber(char));
    }
    for (let i = 0; i < timeString.length; i++) {
      const char = timeString[i];
      numbersTime.push(this.getNumber(char));
    }
    return (
      <div className="clock">
        <div>{numbersDate}</div>
        <div>{numbersTime}</div>
      </div>
    );
  }

  getNumber(num) {
    let className = null;
    switch (num) {
      case '1':
        className = 'one';
        break;
      case '2':
        className = 'two';
        break;
      case '3':
        className = 'three';
        break;
      case '4':
        className = 'four';
        break;
      case '5':
        className = 'five';
        break;
      case '6':
        className = 'six';
        break;
      case '7':
        className = 'seven';
        break;
      case '8':
        className = 'eight';
        break;
      case '9':
        className = 'nine';
        break;
      case '0':
        className = 'zero';
        break;
      case '-':
        return (
          <div className={'number-wrapper'}>
            <div className="part dash" />
          </div>
        );
      case ':':
        return (
          <div className={'number-wrapper'}>
            <div className="part semicolon-up" />
            <div className="part semicolon-down" />
          </div>
        );
      default:
        return <div className={'number-wrapper'} />;
    }
    return (
      <div className={'number-wrapper ' + className}>
        <div className="part a" />
        <div className="part b" />
        <div className="part c" />
        <div className="part d" />
        <div className="part e" />
        <div className="part f" />
        <div className="part g" />
      </div>
    );
  }
}
