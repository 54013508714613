import React from 'react';
import Card from '../Card';
import './Imlementation.css';

function Implementation5(props) {
  return (
    <div className="adcalls-implementation">
      <Card header="Pagina 5" className="color-5" text={<p>Je kan me bellen op 06&nbsp;17007001</p>} />

      <a href="/adcalls-4" className="prev">
        {'< Vorige'}
      </a>
      <a href="/adcalls-6" className="next">
        Volgende >
      </a>
    </div>
  );
}

export default Implementation5;
