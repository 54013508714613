import React from 'react';
import {Link} from 'react-router-dom';
import './App.css';

function Card(props) {
  let header,
    text,
    link = null;
  if (props.header) {
    header = <h2>{props.header}</h2>;
  }

  if (typeof props.text === 'string') {
    text = <p>{props.text}</p>;
  } else {
    text = props.text;
  }

  if (props.link) {
    link = <Link to={props.link}>{props.linkText ? props.linkText : props.link}</Link>;
  }

  let className = 'box';
  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <div className="container">
      <div className={className}>
        <span className="bouncing-square-glass" />
        <div className="content glass">
          {header}
          {text}
          {link}
        </div>
      </div>
    </div>
  );
}

export default Card;
