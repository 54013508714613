import React from 'react';

export default class Css extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div className="container full">
        <a href="/css/404" className="glass simple-card clickable">
          Spinning Cube
        </a>
        <a href="/css/emoji" className="glass simple-card clickable">
          Interactive emoji
        </a>
        <a href="/css/clock" className="glass simple-card clickable">
          Digital Clock
        </a>
      </div>
    );
  }
}
