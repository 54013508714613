import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Menu.css';

function Menu() {
  const [active, setActive] = useState(false);
  const [current, setCurrent] = useState(window.location.pathname);

  const pages = [
    {link: '/', name: 'Homepage'},
    {link: '/about', name: 'About me'},
    {link: '/games', name: 'Games'},
    {link: '/css', name: 'CSS'},
    {link: '/wood', name: 'Wood'},
    {link: '/contact', name: 'Contact'},
  ];

  const items = pages.map((value, index) => {
    return (
      <div key={index} className={'menu-item' + (current === value.link ? ' active' : '')}>
        <Link
          to={value.link}
          className="menu-link"
          onClick={() => {
            setCurrent(value.link);
            setActive(false);
          }}
        >
          {value.name}
        </Link>
      </div>
    );
  });
  const menuItems = active || true ? <ul className="menu-items">{items}</ul> : null;

  return (
    <div className={'menu-back glass' + (active ? ' active' : '')}>
      <div className={'menu-wrapper' + (active ? ' active' : '')}>
        <div className="menu">
          <div className="hamburger-wrapper">
            <div className="hamburger" onClick={() => setActive(!active)}>
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
        {menuItems}
      </div>
    </div>
  );
}

export default Menu;
