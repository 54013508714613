import React from 'react';
import {Link} from 'react-router-dom';
import Card from '../Card';
import './Imlementation.css';

function Implementation1(props) {
  //Missing format in de href

  return (
    <div className="adcalls-implementation">
      <Card
        header="AdCalls Implementatie"
        text={
          <p>
            Wat is er mis met deze implementatie?
            <br />
            <br />
            <a href="tel:+31617007001">0617007001</a>
          </p>
        }
      />
      <Link to="/adcalls-2" className="next">
        Volgende >
      </Link>
    </div>
  );
}

export default Implementation1;
